import React from "react";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation(); 

  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <h2>{t("contact-title")}</h2> 
        <form action="submit_form.php" method="post">
          <label htmlFor="name">{t("contact-name")}</label>
          <input type="text" id="name" name="name" required />
          <label htmlFor="email">{t("contact-email")}</label> 
          <input type="email" id="email" name="email" required />
          <label htmlFor="message">{t("contact-message")}</label> 
          <textarea id="message" name="message" required></textarea>
          <button type="submit" disabled>{t("contact-submit")}</button>

        </form>
      </div>
    </section>
  );
};

export default ContactForm;
