import React, { useEffect, useMemo, useState } from "react";
import { db } from '../config/firebaseConfig'; 
import { collection, getDocs } from 'firebase/firestore';
import Header from "./Header";
import ShimmerProduct from "./ShimmerProduct";
import { t } from "i18next";

const DressMe = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    category: '',
    color: '',
    size: ''
  });
  const [imageIndexes, setImageIndexes] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map(doc => ({ 
          id: doc.id, 
          ...doc.data(),
        }));
        setProducts(productList);
        setFilteredProducts(productList);
        // Initialize image indexes
        const initialIndexes = productList.reduce((acc, product) => {
          acc[product.id] = 0;
          return acc;
        }, {});
        setImageIndexes(initialIndexes);
      } catch (err) {
        setError('Error fetching products: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);


  useEffect(() => {
    const applyFilters = () => {
      let result = products;
      if (filters.category) {
        result = result.filter(product => product.category === filters.category);
      }
      if (filters.color) {
        result = result.filter(product => product.colors.includes(filters.color));
      }
      if (filters.size) {
        result = result.filter(product => product.sizes.includes(filters.size));
      }
      setFilteredProducts(result);
    };

    applyFilters();
  }, [filters, products]);

  const handleFilterChange = (filterType, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const calculateDiscount = (original, discounted) => {
    return Math.round(((original - discounted) / original) * 100);
  };

  // const changeImage = (productId, direction) => {
  //   setImageIndexes(prevIndexes => ({
  //     ...prevIndexes,
  //     [productId]: (prevIndexes[productId] || 0) + direction,
  //   }));
  // };
  const changeImage = (productId, direction) => {
    setImageIndexes(prevIndexes => {
      const product = products.find(p => p.id === productId);
      if (!product) return prevIndexes;
  
      const currentIndex = prevIndexes[productId] || 0;
      const imageCount = product.images.length;
      let newIndex = (currentIndex + direction + imageCount) % imageCount;
      
      return {
        ...prevIndexes,
        [productId]: newIndex
      };
    });
  };
  const uniqueCategories = [...new Set(products.map(product => product.category))];
  const uniqueColors = [...new Set(products.flatMap(product => product.colors))];
  const uniqueSizes = [...new Set(products.flatMap(product => product.sizes))];
  const memoizedFilteredProducts = useMemo(() => filteredProducts.map(product => {
    return {
      ...product,
      currentImage: product.images[product.currentImageIndex]
    };
  }), [filteredProducts]);
  const styles = {
    productCard: {
      border: '1px solid #ddd',
      borderRadius: '8px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
    },
    imageContainer: {
      width: '100%',
      paddingTop: '100%',
      position: 'relative',
      overflow: 'hidden',
    },
    productImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      
      transition: 'opacity 0.5s ease-in-out',
      opacity: 1
    },
    colorSwatches: {
      position: 'absolute',
      bottom: '10px', // Position swatches at the bottom
      left: '10px',   // Align swatches to the left
      display: 'flex',
      gap: '5px',
    },
    colorSwatch: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      border: '1px solid #fff',
      boxShadow: '0 0 3px rgba(0,0,0,0.3)',
    },
    imageNavigation: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      display: 'flex',
      gap: '10px',
    },
    navButton: {
      background: 'rgba(255,255,255,0.7)',
      border: 'none',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    productInfo: {
      padding: '15px',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    productTitle: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    productPrice: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    originalPrice: {
      textDecoration: 'line-through',
      color: '#999',
      marginRight: '10px',
    },
    discountedPrice: {
        fontWeight: 'bold',
        color: '#8A2BE2',
      },
      discountLabel: {
        backgroundColor: '#8A2BE2',
        color: 'white',
        padding: '2px 5px',
        borderRadius: '3px',
        marginLeft: '10px',
        fontSize: '0.8em',
      },
    productDetails: {
      fontSize: '0.9em',
      color: '#666',
    },

    filterContainer: {
        display: 'flex',
        gap: '15px',
        marginBottom: '20px',
        justifyContent: 'center',
        flexWrap: 'wrap', 
      },
      filterSelect: {
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        width: '150px',
        fontSize: '16px',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
      },
      colorSwatchFilter: {
        display: 'flex',
        overflowX: 'auto', // Enable horizontal scrolling
        whiteSpace: 'nowrap', // Prevent wrapping to the next line
        marginBottom: '20px', // Add margin for spacing
        padding: '10px 0', // Add padding for aesthetics
      },
      colorSwatchOption: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        border: '2px solid #ddd', 
        cursor: 'pointer',
        transition: 'transform 0.2s, border 0.2s',
         display: 'inline-block'
      },
      activeSwatch: {
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', 
        border: '2px solid #000',
        transform: 'scale(1.1)', 
      },
  };

  if (loading) {
    return (
      <div className="bg-gray-100 min-h-screen">
    
   <Header/>
   <div style={{ paddingTop: "100px" }}></div>
      <section id="filterable-products" className="filterable-products">
        <div className="container">
          <h2>Shein Products</h2>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
            {Array(6).fill(null).map((_, index) => (
              <ShimmerProduct key={index} />
            ))}
          </div>
        </div>
      </section>
      </div>
      
    );
  }
  

  if (error) {
    return (
      <section id="filterable-products" className="filterable-products">
        <div className="container">
          <h2>Shein Products</h2>
          <p>{error}</p>
        </div>
      </section>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen">
    
   <Header/>
   <div style={{ paddingTop: "100px" }}>
    <section id="filterable-products" className="filterable-products">
      <div className="container">
        <h2>Shein Products</h2>

        {/* Filters */}
        <div style={styles.filterContainer}>
          {/* Category Dropdown */}
           <select
        value={filters.category}
        onChange={(e) => handleFilterChange('category', e.target.value)}
        style={styles.filterSelect}
      >
        <option value="">{t("All Categories")}</option>
        {uniqueCategories.map(category => (
          <option key={category} value={category}>{t(category)}</option>
        ))}
      </select>

          {/* Color Swatches */}
          <div style={styles.colorSwatchFilter}>
            {uniqueColors.map(color => (
              <div
                key={color}
                onClick={() => handleFilterChange('color', color)}
                style={{
                  ...styles.colorSwatchOption,
                  backgroundColor: color,
                  ...(filters.color === color ? styles.activeSwatch : {}),
                }}
              />
            ))}
          </div>

          {/* Size Dropdown */}
          <select
            value={filters.size}
            onChange={(e) => handleFilterChange('size', e.target.value)}
            style={styles.filterSelect}
          >
            <option value="">All Sizes</option>
            {uniqueSizes.map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
        </div>

        {/* Products Grid */}
        <div id="product-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
          {memoizedFilteredProducts.map((product) => (
            <div key={product.id} style={styles.productCard}>
              <div style={styles.imageContainer}>
              <img 
  src={product.images[imageIndexes[product.id] % product.images.length] || '/placeholder-image.jpg'} 
  alt={product.title} 
  style={styles.productImage}
/>

 
                <div style={styles.colorSwatches}>
                  {product.colors.map(color => (
                    <div 
                      key={color} 
                      style={{...styles.colorSwatch, backgroundColor: color}}
                      title={color}
                    ></div>
                  ))}
                </div>
                <div style={styles.imageNavigation}>
                  <button style={styles.navButton} onClick={() => changeImage(product.id, -1)}>❮</button>
                  <button style={styles.navButton} onClick={() => changeImage(product.id, 1)}>❯</button>
                </div>
              </div>
              <div style={styles.productInfo}>
                <h3 style={styles.productTitle}>{product.title}</h3>
                <div style={styles.productPrice}>
                  <span style={styles.originalPrice}>{product.originalPrice}DT</span>
                  <span style={styles.discountedPrice}>{product.discountedPrice}DT</span>
                  {calculateDiscount(product.originalPrice, product.discountedPrice) > 0 && (
                    <span style={styles.discountLabel}>
                      {calculateDiscount(product.originalPrice, product.discountedPrice)}% OFF
                    </span>
                  )}
                </div>
                <p style={styles.productDetails}>{product.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
    </div>
  );

};

export default DressMe;
