import React, { useState } from 'react';
import { FaBox, FaTruck, FaCheckCircle, FaSearch, FaMoon, FaSun } from 'react-icons/fa';
import { db } from '../config/firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

const stages = [
  { id: 1, name: 'في طريق الشحن إلى ألمانيا', icon: <FaTruck />, color: 'text-yellow-400' },
  { id: 2, name: 'في ألمانيا', icon: <FaCheckCircle />, color: 'text-blue-400' },
  { id: 3, name: 'في طريق الشحن إلى تونس', icon: <FaTruck />, color: 'text-yellow-400' },
  { id: 4, name: 'في تونس', icon: <FaCheckCircle />, color: 'text-blue-400' },
  { id: 5, name: 'تم التسليم', icon: <FaCheckCircle />, color: 'text-green-400' },
];

const PackageTracker = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const handleTrack = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setNotFound(false);

    try {
      const q = query(collection(db, "trackingOrders"), where("trackingNumber", "==", trackingNumber));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const orderData = querySnapshot.docs[0].data();
        setTrackingInfo(orderData);
      } else {
        setTrackingInfo(null);
        setNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching order: ", error);
      setTrackingInfo(null);
      setNotFound(true);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const styles = {
    container: {
      minHeight: '100vh',
      background: isDarkMode ? 'linear-gradient(to bottom, #1a202c, #2d3748)' : '#f7fafc',
      color: isDarkMode ? 'white' : '#2d3748',
      padding: '1rem',
    },
    content: {
      maxWidth: '48rem',
      margin: '0 auto',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '2rem',
    },
    form: {
      marginBottom: '2rem',
      position: 'relative',
    },
    input: {
      width: '100%',
      backgroundColor: isDarkMode ? '#2d3748' : 'white',
      border: `2px solid ${isDarkMode ? '#8A2BE2' : '#4a5568'}`,
      borderRadius: '9999px',
      padding: '0.75rem 1.5rem',
      paddingRight: '3rem',
      color: isDarkMode ? 'white' : '#2d3748',
    },
    button: {
      position: 'absolute',
      right: '0.5rem',
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: '#8A2BE2',
      color: 'white',
      borderRadius: '9999px',
      padding: '0.5rem',
    },
    infoCard: {
      backgroundColor: isDarkMode ? '#2d3748' : 'white',
      borderRadius: '0.5rem',
      padding: '1.5rem',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
    infoTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '1.5rem',
    },
    infoGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '1rem',
      marginBottom: '1.5rem',
    },
    infoLabel: {
      color: isDarkMode ? '#a0aec0' : '#4a5568',
    },
    infoValue: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },
    stageContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    stageItem: {
      display: 'flex',
      alignItems: 'center',
    },
    stageIcon: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '3rem',
      height: '3rem',
      borderRadius: '9999px',
      marginRight: '1rem',
      color: 'white',
    },
    stageLine: {
      position: 'absolute',
      top: '3rem',
      left: '50%',
      width: '2px',
      height: '3rem',
    },
    stageText: {
      fontWeight: 'bold',
    },
    stageDate: {
      fontSize: '0.875rem',
      color: isDarkMode ? '#a0aec0' : '#4a5568',
      marginTop: '0.25rem',
    },
    modeToggle: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      backgroundColor: isDarkMode ? '#f7fafc' : '#2d3748',
      color: isDarkMode ? '#2d3748' : '#f7fafc',
      borderRadius: '9999px',
      padding: '0.5rem',
      cursor: 'pointer',
    },
    notFound: {
      textAlign: 'center',
      color: isDarkMode ? '#f56565' : '#e53e3e',
      marginTop: '2rem',
    },
  };

  const getStageColor = (isCompleted, isCurrent) => {
    if (isCompleted) {
      return isDarkMode ? '#8A2BE2' : '#8A28EF';
    } else if (isCurrent) {
      return isDarkMode ? '#FFC107' : '#FFA000';
    } else {
      return isDarkMode ? '#616161' : '#BDBDBD';
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h2 style={styles.title}>تتبع طلبك</h2>
        <div style={styles.modeToggle} onClick={toggleDarkMode}>
          {isDarkMode ? <FaSun /> : <FaMoon />}
        </div>
        <form onSubmit={handleTrack} style={styles.form}>
          <input
            type="text"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            placeholder="أدخل رقم التتبع"
            required
            style={styles.input}
          />
          <button
            type="submit"
            disabled={isLoading || !trackingNumber}
            style={styles.button}
          >
            {isLoading ? (
              <FaBox style={{ width: '1.25rem', height: '1.25rem', animation: 'spin 1s linear infinite' }} />
            ) : (
              <FaSearch style={{ width: '1.25rem', height: '1.25rem' }} />
            )}
          </button>
        </form>

        {notFound && (
          <div style={styles.notFound}>
            <p>رقم التتبع غير موجود.</p>
          </div>
        )}

        {trackingInfo && (
          <div style={styles.infoCard}>
            <h4 style={styles.infoTitle}>معلومات التتبع</h4>
            <div style={styles.infoGrid}>
              <div>
                <p style={styles.infoLabel}>تاريخ إنشاء الطلب</p>
                <p style={styles.infoValue}>{trackingInfo.orderDate}</p>
              </div>
              <div>
                <p style={styles.infoLabel}>تاريخ التوصيل المتوقع</p>
                <p style={styles.infoValue}>{trackingInfo.expectedDeliveryDate}</p>
              </div>
            </div>

            <div style={styles.stageContainer}>
              {stages.map((stage, index) => {
                const currentStatusUpdate = trackingInfo.statusUpdates.length > 0 ? trackingInfo.statusUpdates[trackingInfo.statusUpdates.length - 1] : null;
                const isCompleted = currentStatusUpdate ? index < currentStatusUpdate.currentStage : false;
                const isCurrent = currentStatusUpdate ? index === currentStatusUpdate.currentStage - 1 : false;

                const stageColor = getStageColor(isCompleted, isCurrent);

                return (
                  <div key={stage.id} style={styles.stageItem}>
                    <div style={{
                      ...styles.stageIcon,
                      backgroundColor: stageColor,
                    }}>
                      {React.cloneElement(stage.icon, { style: { width: '1.5rem', height: '1.5rem' } })}
                    </div>
                    <div>
                      <p style={{
                        ...styles.stageText,
                        color: isDarkMode ? (isCompleted || isCurrent ? stageColor : '#E0E0E0') : (isCompleted || isCurrent ? stageColor : '#424242'),
                      }}>{stage.name}</p>
                      {isCurrent && <p style={styles.stageDate}>تاريخ التحديث: {currentStatusUpdate.date}</p>}
                    </div>
                    {index < stages.length - 1 && (
                      <div style={{ ...styles.stageLine, backgroundColor: isCompleted ? stageColor : (isDarkMode ? '#616161' : '#BDBDBD') }} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackageTracker;