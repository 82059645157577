import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation(); 

  return (
    <footer>
      <div className="container">
        <p>
          © 2024 Moonlit Bloom. {t("footer-rights")}{" "}
          <a href="https://cloudsoftware.tn">Cloud Software inc.</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
