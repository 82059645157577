import React, { useState } from 'react';
import { db } from '../../config/firebaseConfig'; // Adjust this path to your firebase config
import { collection, addDoc } from 'firebase/firestore';

// Function to generate a random tracking number
const generateTrackingNumber = () => {
  return 'MB-' + Math.random().toString(36).substring(2, 10).toUpperCase(); // Example tracking number format
};

const AddOrder = () => {
  const [orderDate, setOrderDate] = useState('');
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientPhoneNumber, setClientPhoneNumber] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [firstPaycheck, setFirstPaycheck] = useState('');
  const [remainingBalance, setRemainingBalance] = useState('');
  const [profit, setProfit] = useState('');
  const [purchasedItemsIds, setPurchasedItemsIds] = useState('');
  const [purchaseSite, setPurchaseSite] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const trackingNumber = generateTrackingNumber(); // Generate a tracking number
      const newOrder = {
        trackingNumber, // This will also be included in the orders collection
        orderDate,
        expectedDeliveryDate,
        clientName,
        clientPhoneNumber,
        deliveryAddress,
        totalAmount: parseFloat(totalAmount),
        firstPaycheck: parseFloat(firstPaycheck),
        remainingBalance: parseFloat(remainingBalance),
        profit: parseFloat(profit),
        purchasedItemsIds: purchasedItemsIds.split(',').map(id => id.trim()),
        purchaseSite,
        paymentStatus: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      // Save the order to the orders collection and get the orderId (document ID)
      const orderDocRef = await addDoc(collection(db, 'orders'), newOrder);
      const orderId = orderDocRef.id; // Retrieve the generated order ID

      // Save tracking data to the trackingOrders collection
      await addDoc(collection(db, 'trackingOrders'), {
        trackingNumber,
        orderId, // Add the Firestore generated order ID to tracking data
        orderDate,
        expectedDeliveryDate,
        statusUpdates: [
          {
            currentStage: 1, // You can set this based on your business logic
            date: new Date().toISOString(), // Current timestamp in ISO format
          }
        ],
        createdAt: new Date(),
        updatedAt: new Date(),
      });

      resetForm();
      alert('Order added successfully!');
    } catch (error) {
      console.error('Error adding order: ', error);
      alert('Failed to add order.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setOrderDate('');
    setExpectedDeliveryDate('');
    setClientName('');
    setClientPhoneNumber('');
    setDeliveryAddress('');
    setTotalAmount('');
    setFirstPaycheck('');
    setRemainingBalance('');
    setProfit('');
    setPurchasedItemsIds('');
    setPurchaseSite('');
  };

  return (
    <div className="container mt-5">
      <div className="card shadow-lg">
        <div className="card-body">
          <h3 className="card-title text-center">Add New Order</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="orderDate" className="form-label">Order Date</label>
              <input
                type="date"
                className="form-control"
                id="orderDate"
                value={orderDate}
                onChange={(e) => setOrderDate(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="expectedDeliveryDate" className="form-label">Expected Delivery Date</label>
              <input
                type="date"
                className="form-control"
                id="expectedDeliveryDate"
                value={expectedDeliveryDate}
                onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="clientName" className="form-label">Client Name</label>
              <input
                type="text"
                className="form-control"
                id="clientName"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="clientPhoneNumber" className="form-label">Client Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="clientPhoneNumber"
                value={clientPhoneNumber}
                onChange={(e) => setClientPhoneNumber(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="deliveryAddress" className="form-label">Delivery Address</label>
              <input
                type="text"
                className="form-control"
                id="deliveryAddress"
                value={deliveryAddress}
                onChange={(e) => setDeliveryAddress(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="totalAmount" className="form-label">Total Amount</label>
              <input
                type="number"
                className="form-control"
                id="totalAmount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="firstPaycheck" className="form-label">First Paycheck</label>
              <input
                type="number"
                className="form-control"
                id="firstPaycheck"
                value={firstPaycheck}
                onChange={(e) => setFirstPaycheck(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="remainingBalance" className="form-label">Remaining Balance</label>
              <input
                type="number"
                className="form-control"
                id="remainingBalance"
                value={remainingBalance}
                onChange={(e) => setRemainingBalance(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="profit" className="form-label">Profit</label>
              <input
                type="number"
                className="form-control"
                id="profit"
                value={profit}
                onChange={(e) => setProfit(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="purchasedItemsIds" className="form-label">Purchased Item IDs (from Shein)</label>
              <input
                type="text"
                className="form-control"
                id="purchasedItemsIds"
                value={purchasedItemsIds}
                onChange={(e) => setPurchasedItemsIds(e.target.value)}
                placeholder="Enter IDs separated by commas"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="purchaseSite" className="form-label">Purchase Site</label>
              <input
                type="text"
                className="form-control"
                id="purchaseSite"
                value={purchaseSite}
                onChange={(e) => setPurchaseSite(e.target.value)}
                placeholder="e.g., Shein, AliExpress"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Add Order'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddOrder;
