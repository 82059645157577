import React from "react";

const InstagramSection = () => {
  return (
    <section id="instagram" className="instagram-section">
      <div className="container">
        <h2>Follow Us on <a href="https://www.instagram.com/moonlit_bloom.tn/" target="_blank" rel="noopener noreferrer">Instagram</a></h2>
        <div className="instagram-widget-container">
          <iframe src="https://snapwidget.com/embed/1079322" className="snapwidget-widget" allowTransparency="true" frameBorder="0" scrolling="no" style={{ border: "none", overflow: "hidden", width: "100%", height: "300px" }} title="Posts from Instagram"></iframe>
        </div>
      </div>
    </section>
  );
};

export default InstagramSection;
