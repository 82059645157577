import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'; 
import { db } from '../config/firebaseConfig'; 
import { collection, getDocs } from 'firebase/firestore';
import Header from "./Header";
import ShimmerProduct from "./ShimmerProduct";

const FeaturedProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const calculateDiscount = (original, discounted) => {
    return Math.round(((original - discounted) / original) * 100);
  };

  // Function to shuffle and select 6 random products
  const getRandomProducts = (productList) => {
    const shuffled = productList.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 6);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products'); // Collection name in Firestore
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Include id if needed
        const randomProducts = getRandomProducts(productList); // Get 6 random products
        setProducts(randomProducts);
      } catch (err) {
        setError('Error fetching products: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return (
      <section id="filterable-products" className="filterable-products">
        <div className="container">
          <h2>Featured Products</h2>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
            {Array(6).fill(null).map((_, index) => (
              <ShimmerProduct key={index} />
            ))}
          </div>
        </div>
      </section>
    );
  }
  

  if (error) {
    return (
      <section id="featured-products" className="featured-products">
        <div className="container">
          <h2>Featured Products</h2>
          <p>{error}</p>
        </div>
      </section>
    );
  }

  return (
    
  
    <section id="featured-products" className="featured-products">
      <div className="container">
        <h2>Featured Products</h2>
        <div id="product-grid" className="product-grid">
          {products.map((product) => (
            <div key={product.id} className="product-card">
              <img src={product.images[0]} alt={product.title} className="product-image" />
              <div className="product-info">
                <h3 className="product-title">{product.title}</h3>
                <div className="product-price">
                  <span className="original-price">{product.originalPrice.toFixed(2)} TND</span>
                  <span className="discounted-price">{product.discountedPrice.toFixed(2)} TND</span>
                  <span className="discount-label">-{calculateDiscount(product.originalPrice, product.discountedPrice)}%</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Dress Me Button */}
        <div className="dress-me-btn-container">
          <button className="dress-me-btn" onClick={() => navigate('/dressme')}>
            Dress Me
          </button>
        </div>
      </div>
    </section>
   
  );
};

export default FeaturedProducts;
