import React from "react";
import { useTranslation } from "react-i18next";
import { FaTshirt, FaShippingFast } from "react-icons/fa"; // Import icons

const Hero = () => {
  const { t } = useTranslation();

  return (
    <section id="home" className="hero">
      <div className="hero-content">
        <div className="hero-text">
          <h1>{t("hero-title")}</h1> 
          <p>{t("hero-subtitle")}</p> 

          {/* Track Button */}
          <a href="/track" className="cta-button track-button">
            <FaShippingFast className="icon" /> {/* Shipping icon */}
            {t('nav-track')} 
          </a>

          {/* DressMe Button */}
          <a href="/dressme" className="cta-button dressme-button">
            <FaTshirt className="icon" /> {/* T-shirt icon */}
            {t('nav-dressMe')} 
          </a>
        </div>

        <div className="hero-slideshow">
          <iframe
            src="https://snapwidget.com/embed/1079323"
            className="snapwidget-widget"
            allowTransparency="true"
            frameBorder="0"
            scrolling="no"
            title="Posts from Instagram"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Hero;
