import React, { useState } from 'react';
import { FaPlus, FaSpinner } from 'react-icons/fa';
import { db } from '../../config/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

const AddOrderTracking = ({ onAddOrder }) => {
    const [trackingNumber, setTrackingNumber] = useState('');
    const [orderDate, setOrderDate] = useState('');
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('');
    const [currentStage, setCurrentStage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
  
    // Function to generate random alphanumeric string for tracking number
    const generateTrackingNumber = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = 'MB-';
        for (let i = 0; i < 8; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Generate tracking number if not already filled
        const generatedTrackingNumber = trackingNumber || generateTrackingNumber();

        try {
            const newOrder = {
                trackingNumber: generatedTrackingNumber,
                orderDate,
                expectedDeliveryDate,
                statusUpdates: [
                    { date: new Date().toISOString(), currentStage: parseInt(currentStage) }
                ]
            };

            const docRef = await addDoc(collection(db, "orders"), newOrder);
            console.log("Document written with ID: ", docRef.id);
            resetForm();
        } catch (error) {
            console.error("Error adding document: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setTrackingNumber('');
        setOrderDate('');
        setExpectedDeliveryDate('');
        setCurrentStage(1);
    };

    return (
        <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-md mx-auto" style={{ backgroundColor: 'black', color: 'white' }}>
            <h2 className="text-2xl font-bold text-center mb-6">إضافة طلب جديد للتتبع</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="trackingNumber" className="block text-sm font-medium text-gray-300">
                        رقم التتبع
                    </label>
                    <input
                        type="text"
                        id="trackingNumber"
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.target.value)}
                        placeholder="سيتم توليد رقم تتبع تلقائي"
                        className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>
                <div>
                    <label htmlFor="orderDate" className="block text-sm font-medium text-gray-300">
                        تاريخ الطلب
                    </label>
                    <input
                        type="date"
                        id="orderDate"
                        value={orderDate}
                        onChange={(e) => setOrderDate(e.target.value)}
                        required
                        className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>
                <div>
                    <label htmlFor="expectedDeliveryDate" className="block text-sm font-medium text-gray-300">
                        تاريخ التسليم المتوقع
                    </label>
                    <input
                        type="date"
                        id="expectedDeliveryDate"
                        value={expectedDeliveryDate}
                        onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                        required
                        className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>
                <div>
                    <label htmlFor="currentStage" className="block text-sm font-medium text-gray-300">
                        المرحلة الحالية
                    </label>
                    <select
                        id="currentStage"
                        value={currentStage}
                        onChange={(e) => setCurrentStage(e.target.value)}
                        required
                        className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        <option value="1">في طريق الشحن إلى ألمانيا</option>
                        <option value="2">في ألمانيا</option>
                        <option value="3">في طريق الشحن إلى تونس</option>
                        <option value="4">في تونس</option>
                        <option value="5">تم التسليم</option>
                    </select>
                </div>
                <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    {isLoading ? (
                        <FaSpinner className="animate-spin h-5 w-5" />
                    ) : (
                        <>
                            <FaPlus className="mr-2 h-5 w-5" />
                            إضافة الطلب
                        </>
                    )}
                </button>
            </form>
        </div>
    );
};

export default AddOrderTracking;
