import React, { useState } from 'react';
import { db, storage } from '../config/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const AddProduct = () => {
  const [title, setTitle] = useState('');
  const [originalPrice, setOriginalPrice] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [imageFiles, setImageFiles] = useState([]);
  const [category, setCategory] = useState('Bras');
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [sheinItemId, setSheinItemId] = useState('');  // New field for Shein itemid
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const categories = [
    'Bras', 'Panties', 'Lingerie Sets', 'Babydolls', 'Chemises',
    'Bodysuits', 'Corsets', 'Bustiers', 'Garters & Garter Belts',
    'Teddies', 'Camisoles', 'Slips', 'Robes', 'Nightgowns', 'Bralettes',
    'Shapewear', 'Hosiery', 'Costumes', 'Plus Size Lingerie', 'Bridal Lingerie',
    'Loungewear', 'Maternity Lingerie', 'Sports Bras', 'Sleepwear Sets', 
    '2pcs', 'Underwire Free', 'Lace Lingerie', 'Silk Lingerie'
  ];
  

  const sizeOptions = [
    { label: 'one-size', value: 'onesize' },
    { label: 'one-size+', value: 'onesize+' },
    { label: 'XS (34)', value: 'XS' },
    { label: 'S (36)', value: 'S' },
    { label: 'M (38)', value: 'M' },
    { label: 'L (40/42)', value: 'L (40/42)' },
    { label: 'XL (44)', value: 'XL' },
    { label: 'XXL (46)', value: 'XXL(46)' },
    { label: '3XL (48)', value: '3XL(48)' },
    { label: '4XL (50)', value: '4XL(50)' },
    { label: '5XL (52)', value: '5XL(52)' }
  ];
  

  const colorOptions = [
    'Black', 'White', 'Red', 'Pink', 'Purple', 'Blue', 
    'Green', 'Beige', 'Burgundy', 'Navy', 'Gray', 'Ivory', 
    'Teal', 'Coral', 'Peach', 'Lilac', 'Maroon', 'Lavender', 
    'Turquoise', 'Magenta', 'Mint', 'Chocolate', 'Copper', 
    'Rose Gold', 'Champagne', 'Olive', 'Saffron', 'Gold', 
    'Silver', 'Mustard', 'Cobalt', 'Blush', 'Wine', 'Fuchsia',
    'Violet', 'Mauve'
  ];
  

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
  };

  const handleSizeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSizes([...sizes, value]);
    } else {
      setSizes(sizes.filter(size => size !== value));
    }
  };

  const handleColorChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setColors([...colors, value]);
    } else {
      setColors(colors.filter(color => color !== value));
    }
  };

  const uploadImage = async (file) => {
    const imageRef = ref(storage, `products/${file.name}`);
    await uploadBytes(imageRef, file);
    return getDownloadURL(imageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (imageFiles.length === 0) {
      setMessage('Please select at least one image to upload.');
      return;
    }

    setUploading(true);
    setMessage('Uploading images and adding product...');

    try {
      const imageUrls = await Promise.all(imageFiles.map(uploadImage));

      const docRef = await addDoc(collection(db, "products"), {
        title,
        originalPrice: parseFloat(originalPrice),
        discountedPrice: parseFloat(discountedPrice),
        category,
        sizes,
        colors,
        sheinItemId,  // Store the Shein itemid in Firestore
        images: imageUrls,
      });

      setMessage(`Product added successfully with ID: ${docRef.id}`);

      // Reset form
      setTitle('');
      setOriginalPrice('');
      setDiscountedPrice('');
      setImageFiles([]);
      setCategory('Bras');
      setSizes([]);
      setColors([]);
      setSheinItemId('');  // Reset Shein itemid field
    } catch (error) {
      setMessage(`Error adding product: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="container">
      <h2>Add New Lingerie Product</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Title</label>
          <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Original Price (TND)</label>
          <input type="number" className="form-control" value={originalPrice} onChange={(e) => setOriginalPrice(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Discounted Price (TND)</label>
          <input type="number" className="form-control" value={discountedPrice} onChange={(e) => setDiscountedPrice(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Shein Item ID</label>
          <input type="text" className="form-control" value={sheinItemId} onChange={(e) => setSheinItemId(e.target.value)} required />
        </div>
        <div className="mb-3">
          <label className="form-label">Category</label>
          <select className="form-select" value={category} onChange={(e) => setCategory(e.target.value)}>
            {categories.map((cat) => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Sizes</label>
          <div>
            {sizeOptions.map((size) => (
              <div key={size.value} className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`size-${size.value}`}
                  value={size.value}
                  checked={sizes.includes(size.value)}
                  onChange={handleSizeChange}
                />
                <label className="form-check-label" htmlFor={`size-${size.value}`}>
                  {size.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">Colors</label>
          <div>
            {colorOptions.map((color) => (
              <div key={color} className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`color-${color}`}
                  value={color}
                  checked={colors.includes(color)}
                  onChange={handleColorChange}
                />
                <label className="form-check-label" htmlFor={`color-${color}`}>
                  {color}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">Images</label>
          <input type="file" className="form-control" accept="image/*" onChange={handleImageChange} multiple required />
          <small className="form-text text-muted">You can select multiple images.</small>
        </div>
        <button type="submit" className="btn btn-primary" disabled={uploading}>
          {uploading ? 'Adding Product...' : 'Add Product'}
        </button>
      </form>
      {message && <p className="mt-3">{message}</p>}
    </div>
  );
};

export default AddProduct;
