import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; 
import ReactGA from 'react-ga';
const root = ReactDOM.createRoot(document.getElementById('root'));


const TRACKING_ID = 'G-RKHWM67Q5Q'; 
ReactGA.initialize(TRACKING_ID);
const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};
root.render(
  <React.StrictMode>
   <App logPageView={logPageView} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
