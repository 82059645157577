const ShimmerProduct = () => {
    const shimmerStyles = {
      productCard: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      },
      productInfo: {
        padding: '15px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      },
    };
  
    return (
      <div style={shimmerStyles.productCard}>
        <div className="shimmer shimmer-image"></div>
        <div style={shimmerStyles.productInfo}>
          <div className="shimmer shimmer-line"></div>
          <div className="shimmer shimmer-line" style={{ width: '60%' }}></div>
          <div className="shimmer shimmer-price"></div>
        </div>
      </div>
    );
  };
  export default ShimmerProduct;