// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyBf0OrYDVvPGzv44mZ_gZ4ymJVxOwAhDEs",
    authDomain: "moonlit-bloom.firebaseapp.com",
    projectId: "moonlit-bloom",
    storageBucket: "moonlit-bloom.appspot.com",
    messagingSenderId: "876342820669",
    appId: "1:876342820669:web:ea44938a9c1a98f094cf54",
    measurementId: "G-6J2Z9WY621"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
export { db , storage, auth};
