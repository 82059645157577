// src/components/VisitTracker.js
import React, { useEffect } from 'react';
import { db } from './config/firebaseConfig';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import md5 from 'md5'; // You can use this library to hash the device info

const VisitTracker = () => {
  const generateDeviceId = () => {
    const userAgent = navigator.userAgent;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const deviceId = `${userAgent}-${screenWidth}-${screenHeight}`;
   
   
    return md5(deviceId); // Hash the device info to create a unique ID
  };

  const trackVisit = async () => {
    const deviceId = generateDeviceId(); // Generate a unique device ID

    // Check for unique visits
    const visitRef = collection(db, 'visits');
    const snapshot = await getDocs(visitRef);
    const uniqueDeviceIds = snapshot.docs.map(doc => doc.data().deviceId);

    if (!uniqueDeviceIds.includes(deviceId)) {
      await addDoc(visitRef, { deviceId });
      console.log('Unique visit recorded for device:', deviceId);
    } else {
      console.log('Returning device:', deviceId);
    }

    // Increment total visits (implement this as discussed earlier)
  };

  useEffect(() => {
    trackVisit();
  }, []);

  return null; // You can return any JSX if needed
};

export default VisitTracker;
